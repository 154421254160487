var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report"},[_c('div',{staticClass:"report-options row"},[(!_vm.selectedAction)?_c('base-button',{on:{"click":function($event){return _vm.selectAction('add')}}},[_vm._v(" "+_vm._s(_vm.$t('add_layout'))+" ")]):_vm._e(),(!_vm.selectedAction)?_c('base-button',{on:{"click":function($event){return _vm.selectAction('edit')}}},[_vm._v(" "+_vm._s(_vm.$t('edit_layout'))+" ")]):_vm._e(),(_vm.selectedAction === 'edit')?_c('div',{staticClass:"edit-report"},[_c('Dropdown',{key:("layout-" + _vm.key),attrs:{"options":_vm.reportLayouts,"disabled":false,"name":"layouts","maxItem":_vm.reportLayouts.length,"placeholder":_vm.$t('select_layout')},on:{"selected":_vm.sendLayoutId}})],1):(_vm.selectedAction === 'add'
        && _vm.newLayout)?_c('div',{staticClass:"edit-report"},[_c('base-input',{staticClass:"name-input",attrs:{"placeholder":_vm.$t('name'),"name":"name","type":"text"},model:{value:(_vm.newLayout.name),callback:function ($$v) {_vm.$set(_vm.newLayout, "name", $$v)},expression:"newLayout.name"}})],1):_vm._e(),(_vm.selectedAction)?_c('base-button',{on:{"click":function($event){return _vm.selectAction(null)}}},[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")]):_vm._e()],1),(_vm.selectedAction === 'edit'
      && _vm.layout)?_c('div',{staticClass:"report-options row"},[_c('label',{staticStyle:{"flex":"100%"},attrs:{"for":"name"}},[_c('p',{staticClass:"label-text name"},[_vm._v(" "+_vm._s(_vm.$t('name'))+": ")]),(_vm.layout)?_c('base-input',{staticClass:"name-input",attrs:{"placeholder":_vm.$t('name'),"name":"name","type":"text"},model:{value:(_vm.layout.name),callback:function ($$v) {_vm.$set(_vm.layout, "name", $$v)},expression:"layout.name"}}):_vm._e()],1),_vm._l((Object.entries(_vm.layout).filter(function (ref) {
            var key = ref[0];
            var value = ref[1];

            return typeof(value) === 'boolean';
})),function(ref,i){
      var key = ref[0];
      var value = ref[1];
return _c('label',{key:("layout-" + i),staticStyle:{"flex":"50%"},attrs:{"for":key}},[_c('p',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.$t(("report_editor." + key)))+": ")]),_c('base-checkbox',{staticClass:"check-input",attrs:{"name":key},model:{value:(_vm.layout[key]),callback:function ($$v) {_vm.$set(_vm.layout, key, $$v)},expression:"layout[key]"}})],1)})],2):(_vm.selectedAction === 'add'
      && _vm.newLayout)?_c('div',{staticClass:"report-options row"},_vm._l((Object.entries(_vm.newLayout).filter(function (ref) {
            var key = ref[0];
            var value = ref[1];

            return typeof(value) === 'boolean';
})),function(ref,i){
      var key = ref[0];
      var value = ref[1];
return _c('label',{key:("layout-" + i),staticStyle:{"flex":"50%"},attrs:{"for":key}},[_c('p',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.$t(("report_editor." + key)))+": ")]),_c('base-checkbox',{staticClass:"check-input",attrs:{"name":key},model:{value:(_vm.newLayout[key]),callback:function ($$v) {_vm.$set(_vm.newLayout, key, $$v)},expression:"newLayout[key]"}})],1)}),0):_vm._e(),((_vm.selectedAction === 'edit'
      && _vm.layout)
      || (_vm.selectedAction === 'add'
      && _vm.newLayout))?_c('hr'):_vm._e(),(_vm.selectedAction === 'edit'
      && _vm.layout)?_c('div',{staticClass:"report-options row"},[_c('div',{staticStyle:{"flex":"100%"}},[_c('Dropdown',{key:"lang",staticClass:"lang",attrs:{"options":_vm.langs,"disabled":false,"name":"lang","maxItem":_vm.langs.length,"placeholder":_vm.$t('select_lang'),"language":true},on:{"selected":_vm.selectLang}})],1),_vm._l((Object.keys(_vm.layout.translations).filter(function (key) { return key.includes('methods'); })),function(key,i){return _c('label',{key:("layout-" + i),attrs:{"for":key}},[_c('p',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.$t(("report_editor." + key)))+": ")]),(_vm.selectedLang
          && key.includes('title'))?_c('base-input',{staticClass:"title-input",attrs:{"placeholder":_vm.selectedLang,"name":key,"type":"text"},model:{value:(_vm.layout.translations[key][_vm.selectedLang]),callback:function ($$v) {_vm.$set(_vm.layout.translations[key], _vm.selectedLang, $$v)},expression:"layout.translations[key][selectedLang]"}}):_vm._e(),(_vm.selectedLang
          && key.includes('text'))?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.layout.translations[key][_vm.selectedLang]),expression:"layout.translations[key][selectedLang]"}],staticClass:"text-input",attrs:{"placeholder":_vm.selectedLang,"name":key},domProps:{"value":(_vm.layout.translations[key][_vm.selectedLang])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.layout.translations[key], _vm.selectedLang, $event.target.value)}}}):_vm._e()],1)})],2):(_vm.selectedAction === 'add'
      && _vm.newLayout)?_c('div',{staticClass:"report-options row"},[_c('div',{staticStyle:{"flex":"100%"}},[_c('Dropdown',{key:"lang",staticClass:"lang",attrs:{"options":_vm.langs,"disabled":false,"name":"lang","maxItem":_vm.langs.length,"placeholder":_vm.$t('select_lang'),"language":true},on:{"selected":_vm.selectLang}})],1),_vm._l((Object.keys(_vm.newLayout.translations)),function(key,i){return _c('label',{key:("layout-" + i),attrs:{"for":key}},[_c('p',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.$t(("report_editor." + key)))+": ")]),(_vm.selectedLang
          && key.includes('title'))?_c('base-input',{staticClass:"title-input",attrs:{"placeholder":_vm.selectedLang,"name":key,"type":"text"},model:{value:(_vm.newLayout.translations[key][_vm.selectedLang]),callback:function ($$v) {_vm.$set(_vm.newLayout.translations[key], _vm.selectedLang, $$v)},expression:"newLayout.translations[key][selectedLang]"}}):_vm._e(),(_vm.selectedLang
          && key.includes('text'))?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.newLayout.translations[key][_vm.selectedLang]),expression:"newLayout.translations[key][selectedLang]"}],staticClass:"text-input",attrs:{"placeholder":_vm.selectedLang,"name":key},domProps:{"value":(_vm.newLayout.translations[key][_vm.selectedLang])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newLayout.translations[key], _vm.selectedLang, $event.target.value)}}}):_vm._e()],1)})],2):_vm._e(),((_vm.selectedAction === 'edit'
      && _vm.layout)
      || (_vm.selectedAction === 'add'
      && _vm.newLayout))?_c('hr'):_vm._e(),((_vm.selectedAction === 'edit'
      && _vm.layout)
      || (_vm.selectedAction === 'add'
      && _vm.newLayout))?_c('ModuleSelector',{ref:"moduleSelector",attrs:{"reportLayouts":_vm.reportLayouts,"layout":_vm.layout,"newLayout":_vm.newLayout,"reportModules":_vm.reportModules,"selectedAction":_vm.selectedAction,"moduleTypes":_vm.moduleTypes},on:{"handleErrors":_vm.handleErrors}}):_vm._e(),((_vm.selectedAction === 'edit'
      && _vm.layout)
      || (_vm.selectedAction === 'add'
      && _vm.newLayout))?_c('hr'):_vm._e(),(_vm.selectedAction === 'edit'
      && _vm.layout)?_c('div',{staticClass:"report-options row"},[_c('base-button',{on:{"click":_vm.editReportLayout}},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")])],1):(_vm.selectedAction === 'add')?_c('div',{staticClass:"report-options row"},[_c('base-button',{on:{"click":_vm.addReportLayout}},[_vm._v(" "+_vm._s(_vm.$t('add'))+" ")])],1):_vm._e(),((_vm.selectedAction === 'edit'
      && _vm.layout)
      || (_vm.selectedAction === 'add'
      && _vm.newLayout))?_c('hr'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }