var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"selection-panel",style:([
    _vm.check()
      ? { 'margin-top': '0.5em' }
      : ''
  ])},[_c('div',{staticClass:"client",class:{
      'hide': !_vm.selected
        || _vm.projectManagement.map(function (p) { return p.text; }).includes(_vm.selected)
        || _vm.layoutManagement.includes(_vm.selected)
        || _vm.translations.includes(_vm.selected)
        || _vm.selected === 'edit-aggregator'
        || _vm.selected === 'edit-client'
    }},[(_vm.selected === 'get-user')?_c('div',{staticClass:"client-options"},[_c('Dropdown',{key:("client-" + _vm.key),attrs:{"options":_vm.clients,"disabled":false,"name":"clients","maxItem":_vm.clients.length,"placeholder":_vm.$t('select_client')},on:{"selected":_vm.showClient}}),_c('base-button',{class:{ 'clicked': _vm.displayUsers }},[_vm._v(" "+_vm._s(_vm.$t('show'))+" ")]),_c('base-button',{class:{ 'clicked': _vm.exportUsers },attrs:{"id":"all-users"},on:{"click":function($event){return _vm.showClient('export')}}},[_vm._v(" "+_vm._s(_vm.$t('all_users'))+" ")])],1):_vm._e()]),_c('div',{staticClass:"project",class:{
      'hide': !_vm.selected
        || _vm.selected === 'edit-aggregator'
        || _vm.selected === 'edit-client'
        || _vm.selected === 'edit-project'
        || (!_vm.projectManagement.map(function (p) { return p.text; }).includes(_vm.selected)
        && _vm.selected !== 'add-module'
        && _vm.selected !== 'add-kpi')
    }},[_c('div',{staticClass:"project-options"},[(_vm.selected === 'add-module')?_c('base-button',{on:{"click":function($event){return _vm.selectAction('add')}}},[_vm._v(" "+_vm._s(_vm.$t('report_editor.add_module'))+" ")]):(_vm.selected === 'add-kpi')?_c('KPI',{key:("kpi-" + _vm.key),attrs:{"kpis":_vm.kpis,"allLang":_vm.allLang,"langs":_vm.langs},on:{"handleErrors":_vm.handleErrors,"sendAction":_vm.sendAction,"sendKpi":_vm.sendKpi,"refreshKpis":function($event){return _vm.$emit('refreshKpis')}}}):_vm._e()],1)]),(_vm.displayClient
      && _vm.selected
      && !_vm.projectManagement.map(function (p) { return p.text; }).includes(_vm.selected))?_c('div',{staticClass:"result-table"},[_c('div',{staticClass:"results"},[(_vm.clientResponseData
          && _vm.clientResponseData.length
          && _vm.clientName)?_c('table',{staticClass:"client-details"},[_c('tr',_vm._l((_vm.headers.get_client),function(header,i){return _c('th',{key:i},[_vm._v(" "+_vm._s(header)+" ")])}),0),_c('tr',[_vm._l((_vm.clientResponseData),function(obj,j){return _c('td',{key:j},[_vm._v(" "+_vm._s(obj[Object.keys(obj)[0]])+" ")])}),(_vm.onlyParent[1]
              && _vm.onlyParent[1].top_parent !== null)?_c('td',[_vm._v(" "+_vm._s(_vm.onlyParent[1].top_parent.id)+" ")]):_c('td',[_vm._v("-")]),(_vm.onlyParent[1]
              && _vm.onlyParent[1].top_parent !== null)?_c('td',[_vm._v(" "+_vm._s(_vm.onlyParent[1].top_parent.name)+" ")]):_c('td',[_vm._v("-")])],2)]):_vm._e()])]):_vm._e(),(_vm.responseData)?_c('div',{staticClass:"client users",class:{
      'hide': !_vm.selected
        || _vm.projectManagement.map(function (p) { return p.text; }).includes(_vm.selected)
    }},[_c('div',{staticClass:"first-row"},[_c('div',{staticClass:"user-options"},[(_vm.exportUsers)?_c('h4',{staticClass:"list-title",domProps:{"innerHTML":_vm._s(_vm.$t('all_users'))}}):_vm._e()])]),_c('div',{staticClass:"second-row"},[_c('div',{staticClass:"user-options"},[_c('base-input',{staticClass:"email-input",attrs:{"placeholder":"name@example.com","name":"email","type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('base-button',{attrs:{"disabled":!_vm.responseData
          || _vm.exportUsers},on:{"click":function($event){return _vm.confirm('add-user')}}},[_vm._v(" "+_vm._s(_vm.$t('add_user'))+" ")]),_c('base-button',{attrs:{"disabled":!_vm.responseData
          || _vm.responseData.length === 0
          || !_vm.displayUsers
          || !_vm.activation},on:{"click":function($event){return _vm.confirm('re/deactivate')}}},[_vm._v(" "+_vm._s(_vm.$t('re/deactivate'))+" ")]),_c('Dropdown',{attrs:{"options":_vm.dashboardLanguages,"disabled":false,"name":"dashboardlangs","maxItem":_vm.dashboardLanguages.length,"placeholder":_vm.$t('select_lang'),"language":true},on:{"selected":_vm.selectLanguage}}),_c('base-button',{attrs:{"disabled":!_vm.responseData
          || _vm.responseData.length === 0
          || !_vm.displayUsers
          || !_vm.activation
          || !_vm.selectedLang},on:{"click":_vm.setUserlang}},[_vm._v(" "+_vm._s(_vm.$t("set-language"))+" ")]),_c('base-button',{class:{ 'clicked': _vm.allSelected },attrs:{"disabled":!_vm.responseData
          || _vm.responseData.length === 0
          || !_vm.displayUsers},on:{"click":function($event){$event.preventDefault();return _vm.selectAll()}}},[_c('base-checkbox',{attrs:{"id":"check-all"},on:{"input":function($event){return _vm.selectAll()}}},[_vm._v(" "+_vm._s(_vm.$t('select_all'))+" ")])],1)],1)])]):_vm._e(),(_vm.selected === 'edit-report')?_c('ReportLayout',{attrs:{"allLang":_vm.allLang,"langs":_vm.langs,"reportLayouts":_vm.reportLayouts,"reportModules":_vm.reportModules,"moduleTypes":_vm.moduleTypes},on:{"handleErrors":_vm.handleErrors,"setMsg":_vm.setMsg,"hideResult":_vm.hideResult,"sendLang":_vm.sendLang,"sendAction":_vm.sendAction,"confirm":_vm.confirm,"sendLayoutId":_vm.sendLayoutId}}):_vm._e(),(_vm.selected === 'edit-aggregator' || _vm.selected === 'list-aggregators')?_c('Aggregator',{attrs:{"langs":_vm.langs,"aggregators":_vm.aggregators,"clients":_vm.clients,"policies":_vm.policies,"reportLayouts":_vm.reportLayouts,"projects":_vm.projects,"notMemberProjects":_vm.notMemberProjects,"playCards":_vm.playCards,"videoCards":_vm.videoCards,"calculators":_vm.calculators,"emailTemplates":_vm.emailTemplates},on:{"handleErrors":_vm.handleErrors,"sendAction":_vm.sendAction,"setMsg":_vm.setMsg,"hideResult":_vm.hideResult}}):_vm._e(),(_vm.selected === 'edit-client' || _vm.selected === 'get-client')?_c('Client',{attrs:{"clients":_vm.clients},on:{"handleErrors":_vm.handleErrors,"sendAction":_vm.sendAction,"setMsg":_vm.setMsg,"hideResult":_vm.hideResult}}):_vm._e(),(_vm.selected === 'edit-user' || _vm.selected === 'get-user')?_c('User'):_vm._e(),(_vm.selected === 'edit-project')?_c('Project',{ref:"project",attrs:{"projects":_vm.projects,"clients":_vm.clients,"games":_vm.games},on:{"handleErrors":_vm.handleErrors,"sendAction":_vm.sendAction,"setMsg":_vm.setMsg,"hideResult":_vm.hideResult,"confirm":_vm.confirm,"hideConfirmation":_vm.hideConfirmation}}):_vm._e(),(_vm.clientName
      && _vm.responseData)?_c('hr',{class:{
      'hide': !_vm.selected
        || _vm.projectManagement.map(function (p) { return p.text; }).includes(_vm.selected)
    }}):_vm._e(),(_vm.showConfirmation)?_c('Confirmation',{attrs:{"userManagement":_vm.userManagement,"confirmationNeeded":_vm.confirmationNeeded,"confirmMessage":_vm.confirmMessage,"selected":_vm.selected},on:{"hideConfirmation":_vm.hideConfirmation,"disable":_vm.disable,"addUser":_vm.addUser,"editPolicy":_vm.editPolicy,"editProject":_vm.editProject}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }