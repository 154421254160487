var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project"},[_c('div',{staticClass:"project-options row"},[_c('base-button',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")])],1),(_vm.project)?_c('div',{staticClass:"project-options row"},[_c('div',{staticClass:"project-row"},_vm._l((Object.keys(_vm.project).filter(
          function (key) { return key === 'name' || key === 'client' || key === 'aggregator'; }
        )),function(key,i){return _c('label',{attrs:{"for":key}},[_c('p',{staticClass:"label-text"},[_vm._v(_vm._s(_vm.$t(("project." + key)))+":")]),(key === 'name')?_c('base-input',{staticClass:"name-input",attrs:{"placeholder":_vm.$t('name'),"name":key,"type":"text"},model:{value:(_vm.project[key]),callback:function ($$v) {_vm.$set(_vm.project, key, $$v)},expression:"project[key]"}}):(key === 'client')?_c('Dropdown',{key:_vm.renderCounter,attrs:{"options":_vm.clients,"defaultValue":_vm.getDefaultValue(_vm.project.client, key),"disabled":false,"name":key,"maxItem":_vm.clients.length,"placeholder":_vm.$t('select_client'),"clientid":true},on:{"selected":_vm.selectClient}}):(
            key === 'aggregator' &&
              _vm.project.aggregator &&
              _vm.project.aggregator.id &&
              _vm.project.aggregator.name
          )?_c('p',{staticClass:"label-text aggregator"},[_vm._v(" "+_vm._s(("(" + (_vm.project.aggregator.id) + ") " + (_vm.project.aggregator.name)))+" ")]):(key === 'aggregator' && !_vm.project.aggregator)?_c('p',{staticClass:"label-text aggregator"},[_vm._v(" "+_vm._s(_vm.$t('project.no_aggregator'))+" ")]):_vm._e()],1)}),0)]):_vm._e(),(_vm.project)?_c('hr'):_vm._e(),(_vm.project)?_c('div',{staticClass:"project-options row"},[_c('div',{staticClass:"project-row"},_vm._l((Object.keys(_vm.project)
          .filter(function (key) { return key === 'game' || key === 'level_pack' || key === 'game_skin'; })
          .reverse()),function(key,i){return _c('label',{attrs:{"for":key}},[(
            key === 'game' ||
              (key === 'game_skin' && _vm.gameSkins.length > 0) ||
              (key === 'level_pack' && _vm.levelPacks.length > 0)
          )?_c('p',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.$t(("project." + key)))+": ")]):_vm._e(),(key === 'game')?_c('Dropdown',{attrs:{"options":_vm.games,"defaultValue":_vm.getDefaultValue(_vm.project.game, key),"disabled":false,"name":key,"maxItem":_vm.games.length,"placeholder":_vm.$t('select_game')},on:{"selected":_vm.selectGame}}):(key === 'game_skin' && _vm.gameSkins.length > 0)?_c('Dropdown',{attrs:{"options":_vm.gameSkins,"defaultValue":_vm.getDefaultValue(_vm.project.game_skin, key),"disabled":false,"name":key,"maxItem":_vm.gameSkins.length,"placeholder":_vm.$t('select_skin')},on:{"selected":_vm.selectSkin}}):(key === 'level_pack' && _vm.levelPacks.length > 0)?_c('Dropdown',{attrs:{"options":_vm.levelPacks,"defaultValue":_vm.getDefaultValue(_vm.project.level_pack, key),"disabled":false,"name":key,"maxItem":_vm.levelPacks.length,"placeholder":_vm.$t('select_level_pack')},on:{"selected":_vm.selectLevelPack}}):_vm._e()],1)}),0)]):_vm._e(),(_vm.project)?_c('hr'):_vm._e(),_c('div',{staticClass:"project-options row"},[_c('base-button',{on:{"click":_vm.editProject}},[_vm._v(" "+_vm._s(_vm.$route.params.projectId ? _vm.$t('edit') : _vm.$t('add'))+" ")]),(_vm.resultMessage !== '')?_c('Message',{attrs:{"classes":"","message":_vm.resultMessage},on:{"hideResult":function($event){_vm.resultMessage = ''}}}):_vm._e()],1),(_vm.project)?_c('hr'):_vm._e(),(_vm.showConfirmDialog)?_c('Confirmation',{attrs:{"selected":"edit-project","confirmationNeeded":true,"userManagement":false,"confirmMessage":_vm.$t('confirm_message.edit_project')},on:{"hideConfirmation":_vm.resetClient,"editProject":_vm.sendRequest}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }