var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"result-table"},[_c('div',{staticClass:"results"},[(_vm.resultMessage !== '')?_c('Message',{attrs:{"classes":(_vm.selectedModuleType && (_vm.selectedModuleType.name === 'strength'
        || _vm.selectedModuleType.name === 'bar'|| _vm.selectedModuleType.name === 'polar'))
        || _vm.selectedOption === 'edit-report'
        || _vm.selectedOption === 'edit-aggregator' ? 'move-up' : ''
        || _vm.selectedOption === 'edit-client' ? 'move-up' : '',"message":_vm.resultMessage},on:{"hideResult":_vm.clearMessage}}):_vm._e(),(_vm.responseData
        && _vm.responseData.length
        && _vm.exportUsers)?_c('Export',{attrs:{"exportData":_vm.exportData},on:{"exportTable":_vm.exportTable,"setMsg":_vm.setMsg}}):_vm._e(),(_vm.selectedOption === 'add-layout')?_c('table',{staticClass:"full-size"},[_c('thead',[_c('tr',_vm._l((_vm.headers.layouts),function(header,i){return _c('th',{key:i},[_vm._v(" "+_vm._s(header)+" ")])}),0)]),_c('tbody',_vm._l((_vm.additionalLayouts),function(obj,i){return _c('tr',{key:i},_vm._l((obj),function(prop,j){return _c('td',{key:j},[_vm._v(" "+_vm._s(prop)+" ")])}),0)}),0)]):(_vm.selectedOption === 'edit-report'
        && !_vm.selectedAction
        && _vm.reportLayouts)?_c('table',[_c('thead',[_c('tr',_vm._l((_vm.headers.report_layouts),function(header,i){return _c('th',{key:i},[_vm._v(" "+_vm._s(header)+" ")])}),0)]),_c('tbody',_vm._l((_vm.reportLayouts),function(obj,i){return _c('tr',{key:i},_vm._l((obj),function(prop,j){return _c('td',{key:j},[_vm._v(" "+_vm._s(prop || '-')+" ")])}),0)}),0)]):(_vm.selectedOption === 'add-module'
        && _vm.reportModules[0] && _vm.showReportModuleList)?_c('table',{staticClass:"full-size",class:{
        'report-module': _vm.selectedModuleType,
        'hide': _vm.selectedModuleType
          && _vm.selectedModuleType.name
      }},[_c('thead',[_c('tr',_vm._l((_vm.headers.modules),function(header,i){return _c('th',{key:i},[_vm._v(" "+_vm._s(header)+" ")])}),0)]),_vm._l((_vm.reportModules[0]),function(modules,i){return _c('tbody',{key:i},_vm._l((modules),function(props,j){return _c('tr',{key:j},[_c('td',[_vm._v(" "+_vm._s(i)+" ")]),_vm._l((props),function(value,k){return _c('td',{key:k},[_vm._v(" "+_vm._s(value)+" ")])})],2)}),0)})],2):(_vm.selectedOption === 'add-kpi'
        && _vm.kpis)?_c('table',{staticClass:"full-size",class:{
        'hide': _vm.selectedAction === 'add'
          || _vm.selectedAction === 'edit'
          && _vm.selectedKpi
      }},[_c('thead',[_c('tr',_vm._l((_vm.headers.kpis),function(header,i){return _c('th',{key:i},[_vm._v(" "+_vm._s(header)+" ")])}),0)]),_c('tbody',_vm._l((_vm.filterKpi()),function(kpi,i){return _c('tr',{key:i},_vm._l((kpi),function(value,j){return _c('td',{key:j},[(value === null)?_c('span',[_vm._v(" - ")]):(value === false)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('no'))+" ")]):(value === true)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('yes'))+" ")]):_c('span',[_vm._v(" "+_vm._s(value)+" ")])])}),0)}),0)]):(_vm.selectedOption === 'add-slug')?_c('table',{staticClass:"full-size"},[_c('thead',[_c('tr',_vm._l((_vm.headers.all_slug),function(header,i){return _c('th',{key:i},[_vm._v(" "+_vm._s(header)+" ")])}),0)]),_c('tbody',_vm._l((_vm.allSlug),function(obj,i){return _c('tr',{key:i},_vm._l((obj),function(prop,j){return _c('td',{key:j},[_vm._v(" "+_vm._s(prop)+" ")])}),0)}),0)]):(_vm.selectedOption === 'list-aggregators'
        && !_vm.selectedAction
        && _vm.aggregators)?_c('AggregatorList',{attrs:{"aggregators":_vm.aggregators,"clients":_vm.clients,"headers":_vm.headers.aggregators},on:{"setFilter":_vm.setFilter}}):(_vm.selectedOption === 'get-client'
        && !_vm.selectedAction
        && _vm.clients)?_c('ClientList',{attrs:{"clients":_vm.clients,"headers":_vm.headers.clients},on:{"setFilter":_vm.setFilter}}):(_vm.selectedOption === 'get-user'
        && !_vm.selectedAction
        && _vm.users)?_c('UserList',{attrs:{"users":_vm.responseData,"clients":_vm.clients,"clientUsersHeaders":_vm.headers.get_user,"allUsersHeaders":_vm.headers.export_userlist,"responseData":_vm.responseData,"displayUsers":_vm.displayUsers,"userClients":_vm.userClients,"exportUsers":_vm.exportUsers},on:{"selected":_vm.selected}}):(_vm.selectedOption === 'edit-project'
        && !_vm.selectedAction
        && _vm.projects)?_c('table',[_c('thead',[_c('tr',_vm._l((_vm.headers.projects),function(header,i){return _c('th',{key:i},[_vm._v(" "+_vm._s(header)+" ")])}),0)]),_c('tbody',_vm._l((_vm.projects),function(obj,i){return _c('tr',{key:i},_vm._l((obj),function(prop,j){return _c('td',{key:j},[_vm._v(" "+_vm._s(prop || '-')+" ")])}),0)}),0)]):_vm._e(),_c('div',{staticClass:"pagination-wrapper"},[_c('ul',{staticClass:"pagination justify-content-center"},_vm._l((_vm.pages),function(p){return _c('li',{key:p,class:("page-item " + (p === _vm.page ? 'active' : ''))},[_c('a',{staticClass:"page-link",on:{"click":function($event){return _vm.setPage(p)}}},[_vm._v(_vm._s(p))])])}),0)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }